.nav-container {
  width: 100vw;
  height: 60px;
  background-color: #212529;
  color: aliceblue;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 99;
}

.nav-list {
  padding: 0 70px;
}
.nav-item {
  display: inline;
  font-family: "Poppins", sans-serif;
  margin-right: 30px;

  font-weight: bold;
  letter-spacing: 2px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
  color: aliceblue;
  cursor: pointer;
}
.nav-item:hover {
  color: #ffc700;
}
.nav-btn {
  padding: 0 50px;
}
.nav-btn button {
  display: inline-block;
  padding: 5px 5px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-right: 50px;
  border: 2px solid #ffc700;
  overflow: hidden;
  position: relative;
}
.nav-btn button:hover {
  background-color: #ffc700;
  color: black;
  transition: 1s all ease-in-out;
}
.nav-btn1 button {
  display: inline-block;
  padding: 5px 5px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-right: 50px;
  border: 2px solid #ffc700;
  overflow: hidden;
  position: relative;
}
.nav-hamburger {
  display: none;
}
.hamburger-ico {
  width: 50px;
  height: 50px;
  padding: 0 10px;
  color: aliceblue;
  display: flex;
  z-index: 1;
  /* margin-left: -50%; */
  cursor: pointer;
  border-radius: 5px;
}
.nav-hamburger-list {
  display: none;
}

@media screen and (max-width: 1304px) {
  .nav-item {
    display: none;
  }
  .nav-hamburger {
    display: flex;
  }
  .nav-btn {
    margin-right: auto;
  }
  .nav-btn1 {
    display: none;
  }
  .nav-list {
    padding: 0;
  }
  .nav-hamburger-list {
    position: fixed;
    height: calc(100vh - 0px);
    width: 100vw;
    background-color: #212529;
    top: 60px;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    transition: all 1s ease;
  }
  .nav-hamburger-item {
    margin-bottom: 50px;
    /* font-size: 50px; */
    font-weight: 600;

    font-family: "poppins", sans-serif;
    text-transform: uppercase;
  }
  .nav-hamburger-item a {
    color: aliceblue;
    text-decoration: none;
  }
  .nav-container {
    position: fixed;
  }
}

@media screen and (max-width: 490px) {
  .nav-btn button {
    padding: 10px 10px;
    font-size: 7px;
    color: black;
    display: none;
  }
  .nav-btn1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .nav-btn1 button {
    padding: 15px 9px;
    font-size: 12px;
    font-weight: bold;
    color: #212529;
  }
  .hamburger-icon {
    padding: 0 20px;
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 300px) {
  .nav-btn button {
    padding: 7px 7px;
    font-size: 7px;
    margin-right: auto;
    display: none;
  }
  .hamburger-ico {
    padding: 0 10px;
    width: 30px;
    height: 30px;
  }
  .nav-container {
    /* height: 50px; */
  }
}
@media screen and (max-width: 213px) {
  .nav-hamburger-item a {
    /* display: none; */
  }
}
