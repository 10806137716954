.funds__container {
  background-color: black;
  color: #ddd;
  background-image: url("../assets/393.jpg");
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.funds__main__title {
  margin-bottom: 16px;
  font-family: 'Monoton', cursive;
  font-size: 60px;
  color: #ddd;
  word-spacing: 10px;
}
.funds__main__title2 {
  margin-bottom: 16px;
  font-size: 30px;
  color: #ddd;
  word-spacing: 10px;
}

.funds__top_p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-family: 'Merienda', cursive;
  text-align: center;
  margin-top: 50px;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 20px;
}
.network__typer {
  display: flex;
  width: 85%;
  padding: 30px;
  margin-bottom: 5px;
  background-color: #222222;
  border-radius: 10px;
}
.f {
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.y {
  flex: 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.network__typer2 {
  margin-top: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #1a1c20;
  color: #ddd;
  width: 85%;
  padding: 30px;
  justify-content: space-around;
  margin-bottom: 5px;
}
.network__name {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  font-weight: 500;
}
.network {
  font-size: 24px;
  color: #ddd;
  font-weight: 500;
}
.network__name2 {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
}
.network2 {
  font-size: 20px;
  color: #ddd;
  font-weight: 400;
  font-style: normal;
}
.fund__bottom__para {
  text-align: center;
  padding: 0 30px;
}
.fund__bottom__para p {
  font-family: Quicksand, sans-serif;
  font-size: 25px;
  margin-top: 30px;
  line-height: 1.5;
  margin-bottom: 70px;
}

@media screen and (max-width: 1082px) {
  .funds__main__title {
    font-size: 40px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 25px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 20px;
  }
  .network__name {
    font-size: 20px;
  }
  .network {
    font-size: 20px;
  }
  .network__name2 {
    font-size: 17px;
  }
  .network2 {
    font-size: 17px;
  }
  .fund__bottom__para p {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 752px) {
  .funds__main__title {
    font-size: 30px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 16px;
  }
  .network__name {
    font-size: 16px;
  }
  .network {
    font-size: 16px;
  }
  .network__name2 {
    font-size: 14px;
  }
  .network2 {
    font-size: 14px;
  }
  .fund__bottom__para p {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 590px) {
  .funds__main__title {
    font-size: 25px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 15px;
  }
  .network__name {
    font-size: 15px;
  }
  .network {
    font-size: 15px;
  }
  .network__name2 {
    font-size: 13px;
  }
  .network2 {
    font-size: 13px;
  }
  .fund__bottom__para p {
    font-size: 13px;
    text-align: center;
  }
}

@media screen and (max-width: 478px) {
  .funds__main__title {
    font-size: 20px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 17px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 14px;
    padding: 0 10px;
  }
  .network__name {
    font-size: 14px;
  }
  .network {
    font-size: 14px;
  }
  .network__name2 {
    font-size: 12px;
  }
  .network2 {
    font-size: 12px;
  }
  .fund__bottom__para p {
    font-size: 12px;
    text-align: center;
  }
  .network__typer {
    width: 90%;
  }
  .network__typer2 {
    width: 90%;
  }
}

@media screen and (max-width: 387px) {
  .network__typer {
    width: 95%;
    flex-direction: column;
    gap: 10px;
  }
  .network__typer2 {
    width: 95%;
    flex-direction: column;
    gap: 10px;
  }
}
