.utility__container {
  background-color: #fbdc1f;
  /* padding: 0px 50px; */
  display: flex;
  width: 100%;
  overflow: hidden;
}
.img__utility {
  width: 50%;
  height: 60vh;

  object-fit: cover;
  border-radius: 20px;
}
.utility__left {
  flex: 1.3;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 70px 0px;
}
.utility__right {
  flex: 1;
  margin-left: -6%;
  display: flex;
  align-items: left;
  /* justify-content: center; */
  padding: 40px 0px;
  flex-direction: column;
}
.utility__title {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 -10px 18px;
  color: #000;
  font-family: "Fredoka One", cursive;
  font-size: 28px;
}
.utilty__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.utility__list__contain {
  display: flex;
  gap: 10px;
}
.utility__list__contain span {
  color: #000;
  margin-left: -10px;
  font-weight: 600;
  width: 80%;
  font-size: 26px;
  font-family: "Caveat", cursive;
  line-height: 1.6;
}
.rightmark__styler {
}
.imcheckbox {
  color: #10dc45;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  top: 8px;
}

@media screen and (max-width: 1178px) {
  .utility__left {
    flex: 1;
  }
  .img__utility {
    width: 70%;
    height: 50vh;
    object-fit: cover;
    border-radius: 40px;
  }
  .utility__right {
    margin-left: 0;
  }
}

@media screen and (max-width: 973px) {
  .utility__title {
    font-size: 25px;
  }
  .utility__list__contain span {
    font: 20px;
  }
}

@media screen and (max-width: 901px) {
  .utility__container {
    flex-direction: column;
    height: auto;
  }
  .utility__right {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .utility__list__contain {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 777px) {
  .img__utility {
    width: 60%;
    height: 60vh;
  }
  .utility__left {
    padding: 50px 0px;
    margin-bottom: 0;
  }
  .utility__title {
    width: 100%;
    text-align: center;
  }
  .utility__list__contain span {
    font-size: 19px;
  }
  .utility__title {
    font-size: 23px;
  }
}
@media screen and (max-width: 568px) {
  .img__utility {
    width: 90%;
    height: auto;
    border-radius: 15px;
  }
}
@media screen and (max-width: 540px) {
  .utility__title {
    font-size: 19px;
  }
  .utility__list__contain span {
    font-size: 14px;
  }
}
