@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
.dao__container {
  display: flex;
  background-color: black;
  color: white;
  width: 100%;
}
.dao__para__title {
  font-family: "Monoton", cursive;
  font-weight: 400;
  font-size: 47px;
  text-align: center;
  color: #caced3;
}
.daimond__para {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* min-width: 100px; */
}
.diamond__sub__para {
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
  font-size: 23px;
  /* max-width: 700px; */
  width: 90%;
  text-align: center;
  color: #caced3;
  line-height: 1.6;
}
.dao__para {
  flex: 1 1;
  margin-top: 120px;
  margin-bottom: 70px;
  padding: 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dao__videocard {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-left: -13%;
  margin-top: 5%;
  /* margin-left: -20%; */
}
.dao__btns {
  display: inline-block;
  padding: 10px 44px;
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  border: 3px solid #ffc700;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  margin-top: 30px;
}

.dao__btns:hover {
  background-color: #ffc700;
}
.video video {
  width: 550px;
  height: 100%;
}

@media screen and (max-width: 1351px) {
  .dao__para {
    padding: 0 50px;
  }
  .dao__videocard {
    margin-left: -5%;
  }
}

@media screen and (max-width: 1265px) {
  .video video {
    width: 100%;
  }
}

@media screen and (max-width: 1125px) {
  .dao__container {
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .diamond__sub__para {
    max-width: 1100px;
    width: 100%;
  }
  .dao__videocard {
    margin-left: 0;
    /* margin-top: 0; */
    margin-bottom: 0px;
  }
  .dao__btns {
    margin-bottom: 0;
  }
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .video video {
    width: 90%;
  }
}

@media screen and (max-width: 684px) {
  .dao__para__title {
    font-size: 35px;
  }
  .diamond__sub__para {
    font-size: 17px;
  }
}

@media screen and (max-width: 553px) {
  .dao__para__title {
    font-size: 25px;
    margin-top: -30px;
  }
  .diamond__sub__para {
    font-size: 14px;
  }
  .video video {
    width: 100%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 458px) {
  .dao__para__title {
    font-size: 19px;
  }
  .diamond__sub__para {
    padding: 0;
    width: 100%;
  }
  .dao__para {
    padding: 0 20px;
  }
  .dao__btns {
    font-size: 12px;
    padding: 10px 30px;
  }
}

* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 4px;
}
.scroll-to-top{
  background-color: #ffc700 !important;
  color: aliceblue !important;
 
}

.nav-container {
  width: 100vw;
  height: 60px;
  background-color: #212529;
  color: aliceblue;
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.nav-list {
  padding: 0 70px;
}
.nav-item {
  display: inline;
  font-family: "Poppins", sans-serif;
  margin-right: 30px;

  font-weight: bold;
  letter-spacing: 2px;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
  color: aliceblue;
  cursor: pointer;
}
.nav-item:hover {
  color: #ffc700;
}
.nav-btn {
  padding: 0 50px;
}
.nav-btn button {
  display: inline-block;
  padding: 5px 5px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-right: 50px;
  border: 2px solid #ffc700;
  overflow: hidden;
  position: relative;
}
.nav-btn button:hover {
  background-color: #ffc700;
  color: black;
  transition: 1s all ease-in-out;
}
.nav-btn1 button {
  display: inline-block;
  padding: 5px 5px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  margin-right: 50px;
  border: 2px solid #ffc700;
  overflow: hidden;
  position: relative;
}
.nav-hamburger {
  display: none;
}
.hamburger-ico {
  width: 50px;
  height: 50px;
  padding: 0 10px;
  color: aliceblue;
  display: flex;
  z-index: 1;
  /* margin-left: -50%; */
  cursor: pointer;
  border-radius: 5px;
}
.nav-hamburger-list {
  display: none;
}

@media screen and (max-width: 1304px) {
  .nav-item {
    display: none;
  }
  .nav-hamburger {
    display: flex;
  }
  .nav-btn {
    margin-right: auto;
  }
  .nav-btn1 {
    display: none;
  }
  .nav-list {
    padding: 0;
  }
  .nav-hamburger-list {
    position: fixed;
    height: calc(100vh - 0px);
    width: 100vw;
    background-color: #212529;
    top: 60px;
    list-style: none;
    margin: 0;
    padding: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 999;
    transition: all 1s ease;
  }
  .nav-hamburger-item {
    margin-bottom: 50px;
    /* font-size: 50px; */
    font-weight: 600;

    font-family: "poppins", sans-serif;
    text-transform: uppercase;
  }
  .nav-hamburger-item a {
    color: aliceblue;
    text-decoration: none;
  }
  .nav-container {
    position: fixed;
  }
}

@media screen and (max-width: 490px) {
  .nav-btn button {
    padding: 10px 10px;
    font-size: 7px;
    color: black;
    display: none;
  }
  .nav-btn1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .nav-btn1 button {
    padding: 15px 9px;
    font-size: 12px;
    font-weight: bold;
    color: #212529;
  }
  .hamburger-icon {
    padding: 0 20px;
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 300px) {
  .nav-btn button {
    padding: 7px 7px;
    font-size: 7px;
    margin-right: auto;
    display: none;
  }
  .hamburger-ico {
    padding: 0 10px;
    width: 30px;
    height: 30px;
  }
  .nav-container {
    /* height: 50px; */
  }
}
@media screen and (max-width: 213px) {
  .nav-hamburger-item a {
    /* display: none; */
  }
}

.homer {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 110vh;
  background-image: url(/static/media/393.763f58c9.jpg);
  background-position: 50% 50%;
  background-size: cover;
}
.homerleft {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px;
  /* margin-top: -5%; */
}
.fgs {
  width: 80%;
  font-size: 16px;
  margin-top: 20px;
  color: #ddd;
  text-align: center;
}
.homerleft img {
  width: 70%;
}
.homerright {
  flex: 1.4 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.topicheader {
  position: relative;
  font-size: 60px;
  color: #ddd;
  font-family: 'Arima Madurai', cursive;
  margin-bottom: 10px;
}
.right__sub__title {
  color: #ddd;
  margin-top: -20px;
  text-align: center;
  font-weight: 700;
}
.sub_right__para {
  font-size: 16px;
  margin-top: 20px;
  width: 50%;
  color: #ddd;
  text-align: center;
  /* margin-bottom: 40px; */
}
.inputsect {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.textInput {
  margin: 20px;
  border-color: #d3af37;
  font-size: 20px;
  width: 200px;
}
video {
  width: 350px;
  margin-bottom: 30px;
}
.OIKNO {
  border: none;
  background-color: #ffc700;
  padding: 10px;
  font-weight: 700;
  color: #fff;
  width: 200px;
  cursor: pointer;
  box-shadow: 0 6px 0 -2px hsl(0deg 0% 98% / 30%);
}
@media screen and (max-width: 1037px) {
  .homer {
    flex-direction: column;
    height: auto;
  }
  video {
    width: 80%;
    height: auto;
  }

  .homerleft {
    margin-top: 60px;
  }

  .homerright {
    flex: 1 1;
    margin-bottom: 40px;
    /* margin-top: -100px; */
  }
}

@media screen and (max-width: 700px) {
  .topicheader {
    font-size: 40px;
  }
  .right__sub__title {
    font-size: 24px;
  }
  .sub_right__para {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {
  .right__sub__title {
    font-size: 18px;
  }
  .topicheader {
    font-size: 35px;
  }
  .homerleft {
    padding: 0 20px;
  }
}

@media screen and (max-width: 450px) {
  .topicheader {
    font-size: 23px;
  }
  .right__sub__title {
    font-size: 14px;
  }
  .sub_right__para {
    font-size: 14px;
  }
  .fgs {
    font-size: 14px;
   
  }
 
  .fgs a {
    font-size: 11px;
  }
}

.utility__container {
  background-color: #fbdc1f;
  /* padding: 0px 50px; */
  display: flex;
  width: 100%;
  overflow: hidden;
}
.img__utility {
  width: 50%;
  height: 60vh;

  object-fit: cover;
  border-radius: 20px;
}
.utility__left {
  flex: 1.3 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  padding: 70px 0px;
}
.utility__right {
  flex: 1 1;
  margin-left: -6%;
  display: flex;
  align-items: left;
  /* justify-content: center; */
  padding: 40px 0px;
  flex-direction: column;
}
.utility__title {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 -10px 18px;
  color: #000;
  font-family: "Fredoka One", cursive;
  font-size: 28px;
}
.utilty__list {
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.utility__list__contain {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.utility__list__contain span {
  color: #000;
  margin-left: -10px;
  font-weight: 600;
  width: 80%;
  font-size: 26px;
  font-family: "Caveat", cursive;
  line-height: 1.6;
}
.rightmark__styler {
}
.imcheckbox {
  color: #10dc45;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  top: 8px;
}

@media screen and (max-width: 1178px) {
  .utility__left {
    flex: 1 1;
  }
  .img__utility {
    width: 70%;
    height: 50vh;
    object-fit: cover;
    border-radius: 40px;
  }
  .utility__right {
    margin-left: 0;
  }
}

@media screen and (max-width: 973px) {
  .utility__title {
    font-size: 25px;
  }
  .utility__list__contain span {
    font: 20px;
  }
}

@media screen and (max-width: 901px) {
  .utility__container {
    flex-direction: column;
    height: auto;
  }
  .utility__right {
    display: flex;
    align-items: center;
    padding: 0;
  }
  .utility__list__contain {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 777px) {
  .img__utility {
    width: 60%;
    height: 60vh;
  }
  .utility__left {
    padding: 50px 0px;
    margin-bottom: 0;
  }
  .utility__title {
    width: 100%;
    text-align: center;
  }
  .utility__list__contain span {
    font-size: 19px;
  }
  .utility__title {
    font-size: 23px;
  }
}
@media screen and (max-width: 568px) {
  .img__utility {
    width: 90%;
    height: auto;
    border-radius: 15px;
  }
}
@media screen and (max-width: 540px) {
  .utility__title {
    font-size: 19px;
  }
  .utility__list__contain span {
    font-size: 14px;
  }
}

.nodes__container {
  background-color: black;
  /* margin-top: -9px; */
  text-align: center;
  color: #ddd;
}

.nodes__main__title {
  margin-bottom: 16px;
  font-family: "Monoton", cursive;
  font-size: 60px;

  font-weight: 400;
  line-height: 1.3;
  margin-top: 50px;
  letter-spacing: 4px;
  word-spacing: 10px;
}
.hw {
}

.nodes__top__para {
  text-align: center;
  max-width: 600px;
  font-family: "Quicksand", sans-serif;
  color: #fff;
  margin: 50px auto;
  font-size: 25px;
}
.nodes__ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}
.nodes__li__contains {
  display: flex;
  align-items: left;
  list-style: decimal;
}
.nodes__li__contains p {
  text-align: left;
  font-family: "Quicksand", sans-serif;

  font-size: 25px;
  padding: 0px 150px;
}
.jk {
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .nodes__main__title {
    font-size: 40px;
  }
  .nodes__top__para {
    font-size: 19px;
  }
  .nodes__li__contains p {
    font-size: 18px;
    padding: 0 70px;
    text-align: left;
  }
}

@media screen and (max-width: 866px) {
  .nodes__main__title {
    font-size: 34px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 654px) {
  .nodes__main__title {
    font-size: 30px;
    padding: 0 10px;
  }
  .nodes__li__contains p {
    font-size: 17px;
  }
  .nodes__top__para {
    font-size: 18px;
  }
}

@media screen and (max-width: 552px) {
  .nodes__main__title {
    font-size: 20px;
    margin-top: -20px;
  }
  .nodes__li__contains p {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 20px;
    text-align: center;
  }
  .nodes__top__para {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 20px;
  }
}
@media screen and (max-width: 458px) {
  .nodes__main__title {
    font-size: 17px;
    padding: 0px 5px;
  }
  .nodes__top__para {
    font-size: 12px;
  }
  .nodes__li__contains p {
    font-size: 12px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
.timeline-section {
  background-color: #000;
  min-height: 100vh;
  padding: 100px 10px;
}
.timeline-items {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.timeline-items::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  border: 10px dotted #ffc700;
  left: calc(50% - 16px);
}
.timeline-item {
  margin-bottom: 40px;
  width: 100%;
  position: relative;
}
.timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 30px);
  text-align: right;
}
.timeline-item:nth-child(even) {
  padding-left: calc(50% + 30px);
}
.timeline-dot {
  /* height: 16px;
	width: 16px;
	background-color: #eaa023;
	position: absolute;
	left: calc(50% - 8px);
	border-radius: 50%;
	top:10px; */
  height: 36px;
  width: 36px;
  background-color: #d3af37;
  position: absolute;
  left: calc(50% - 20px);
  border-radius: 50%;
  box-shadow: inset 0 0 5px #999, 0 0 0 5px #dbdbdb, 0 0 13px #000;
  top: 50%;
}

.timeline-date {
  font-size: 18px;
  color: #eaa023;
  margin: 6px 0 15px;
}
.timeline-content {
  padding: 30px 40px;
  object-fit: cover;
  /* background-image: url("../../assets/leftroad.webp"); */
}
.timeline-item:nth-child(odd) {
}
.df {
  position: relative;
  display: flex;
  grid-gap: 7px;
  gap: 7px;
}
.df:nth-child(2) {
  display: flex;
  justify-content: flex-start;
}

.timeline-content h3 {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 18px;
  color: #000;
  font-family: "Fredoka One", cursive;
  text-align: left;
}
.timeline-content p {
  font-family: Merienda, cursive;
  text-align: left;
  font-size: 20px;
}

@media screen and (max-width: 990px) {
  .timeline-items {
    padding: 0 30px;
  }
  .timeline-dot {
    width: 30px;
    height: 30px;
  }
  .timeline-items::before {
    width: 3px;
  }
}
/* responsive */
@media (max-width: 767px) {
  .timeline-items::before {
    left: 7px;
  }
  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 37px;
  }
  .timeline-content p {
    font-size: 17px;
  }
  .timeline-dot {
    left: -3.6%;
    top: 49%;
  }
}

@media screen and (max-width: 708px) {
  .timeline-dot {
    width: 20px;
    height: 20px;
  }
  .timeline-items::before {
    border: 7px dotted #ffc700;
  }
  .timeline-item:nth-child(even) {
    padding-left: 20px;
  }
  .timeline-item:nth-child(odd) {
    padding-left: 20px;
  }
  .timeline-content {
    padding: 25px 40px;
  }
}
@media screen and (max-width: 602px) {
  .timeline-items {
    /* padding: 0 10px; */
  }
  .timeline-dot {
    margin-left: -1%;
  }
  .timeline-content p {
    font-size: 14px;
  }
  .timeline-content h3 {
    font-size: 16px;
  }
}

.main__bot__container {
  background-color: black;
  color: #ddd;
  width: 100%;
  overflow: hidden;
  text-align: center;

  /* height: calc(100% + 200px); */
}
.nc {
  /* margin-top: -30%; */
  margin-bottom: 30px;
}

.bot__para {
  /* margin-top: 10%; */
  padding: 10% 20px;
}

.main-bot {
  width: 100vw;
  background: black;
  display: flex;
  height: 100vh;
  color: aliceblue;
}
@-webkit-keyframes spin3 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@keyframes spin3 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin4 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin4 {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.bot-topic {
  margin-bottom: 36px;
  font-family: "Monoton", cursive;
  font-size: 62px;
  color: #ddd;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 50px;
  letter-spacing: 4px;
  /* word-spacing: 10px; */
}
.divided-items {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  min-height: 100vh;
}
.left-item {
  margin-top: 380px;

  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bot-img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  -webkit-animation: spin4 5s linear infinite;
          animation: spin4 5s linear infinite;
  /* animation: spin4 2s infinite linear; */
}
.bot-img1 {
  width: 350px;
  height: 350px;
  border-radius: 50%;

  -webkit-animation: spin3 5s infinite linear;

          animation: spin3 5s infinite linear;
}
.center-item {
  flex: 1 1;
  margin-top: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.bot-para {
  /* max-width: 700px; */
  margin-left: auto;
  margin-right: auto;
  color: #ddd;
  /* margin-bottom: 10px; */
  /* margin-top: 50px; */
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
}
.bot-paras {
  margin-left: auto;
  margin-right: auto;
  color: #ddd;
  /* margin-bottom: 10px; */
  /* margin-top: 50px; */
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
}

.right-item {
  flex: 1 1;
  margin-top: 380px;

  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1354px) {
  .bot-topic {
    font-size: 40px;
  }
  .left-item {
    margin-top: -10px;
  }
  .right-item {
    margin-top: -10px;
  }
  .center-item {
    margin-top: 100px;
  }
  .nc {
    margin-bottom: 0;
  }
  .main-bot {
    height: 70vh;
  }
  .bot-para {
    padding: 0px;
  }
}

@media screen and (max-width: 1259px) {
  .bot-topic {
    padding: 0 10px;
  }
  .bot-img {
    width: 270px;
    height: 270px;
  }
  .bot-img1 {
    width: 270px;

    height: 270px;
  }
  .bot-para {
    font-size: 20px;
  }
  .bot-paras {
    font-size: 18px;
  }
}

@media screen and (max-width: 1012px) {
  .bot-topic {
    font-size: 30px;
  }
  .bot-img {
    width: 200px;
    height: 200px;
  }
  .bot-img1 {
    width: 200px;
    height: 200px;
  }
  .bot-para {
    font-size: 17px;
  }
  .bot-paras {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 684px) {
  .bot-topic {
    font-size: 25px;
  }
  .divided-items {
    flex-direction: column;
  }
  .left-item {
    margin-top: 150px;
  }
  .bot-img1 {
    display: none;
  }
  .center-item {
    margin-top: 0;
  }
  .right-item {
    margin-top: 0;
  }
  .bot-para {
    padding: 0 20px;
  }
  .bot-paras {
    padding: 0 20px;
  }
}

@media screen and (max-width: 553px) {
  .bot-topic {
    font-size: 19px;
    padding: 0 10px;
  }
  .bot-para {
    font-size: 14px;
  }
  .bot-paras {
    font-size: 14px;
  }
}

@media screen and (max-width: 458px) {
  .bot-topic {
    font-size: 15px;
  }
  .bot-para {
    font-size: 12px;
  }
  .bot-paras {
    font-size: 12px;
  }
  .bot-img {
    width: 180px;
    height: 180px;
  }
}

.economics__container {
  background-color: #000;
  width: 100%;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.economics__title {
  margin-bottom: 16px;
  font-family: "Monoton", cursive;
  font-size: 60px;
  color: #ddd;
  font-weight: 400;
  word-spacing: 10px;
}
.economics__para {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  margin-top: 50px;
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
  text-align: center;
  line-height: 1.6;
}
.vf {
  background-color: #ddd;
  margin-bottom: 100px;
}
@media screen and (max-width: 1118px) {
  .economics__title {
    font-size: 37px;
  }
  .economics__para {
    font-size: 19px;
  }
}
@media screen and (max-width: 750px) {
  .economics__title {
    font-size: 29px;
  }
  .economics__para {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 560px) {
  .economics__title {
    font-size: 20px;
  }
  .economics__para {
    padding: 0 20px;
    font-size: 17px;
  }
}
@media screen and (max-width: 460px) {
  .economics__title {
    font-size: 19px;
  }
  .economics__para {
    padding: 0 10px;
    font-size: 14px;
  }
}

.ct-socials {
  position: fixed;
  top: 20%;
  right: 0;
  list-style: none;
  padding-left: 0;
  z-index: 10;
  margin: 0;
  transition: right 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
}
.ct-socials li {
  padding: 0px 0;
}
.ct-socials li:hover {
  background-color: #fff;
}
.ct-socials li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffc700;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 60px;
  height: 54px;
  transition: all 0.15s ease-in-out;
  float: right;
}
.ct-socials li a:hover {
      width: 54px;
      padding-left: 0;
      text-decoration: none;
      background: #fff;
}
.section.triad-section {
  margin-top: 10px;
}
section.section h2 {
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0;
}
h2 {
  color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  letter-spacing: -1px;
}
section.section p.sub-heading {
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;
}
section.triad-section .triad-sub-section {
  padding-right: 60px;
}
section p.detail-paragraph:first-child {
  margin-top: 0;
}
section p.detail-paragraph {
  font-family: "Open Sans Condensed", sans-serif;
  margin-top: 40px;
  font-size: 18px;
  color: #000;
}
b,
strong {
  font-weight: 700;
}

.funds__container {
  background-color: black;
  color: #ddd;
  background-image: url(/static/media/393.763f58c9.jpg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.funds__main__title {
  margin-bottom: 16px;
  font-family: 'Monoton', cursive;
  font-size: 60px;
  color: #ddd;
  word-spacing: 10px;
}
.funds__main__title2 {
  margin-bottom: 16px;
  font-size: 30px;
  color: #ddd;
  word-spacing: 10px;
}

.funds__top_p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-family: 'Merienda', cursive;
  text-align: center;
  margin-top: 50px;
  font-weight: 400;
  font-size: 25px;
  margin-bottom: 20px;
}
.network__typer {
  display: flex;
  width: 85%;
  padding: 30px;
  margin-bottom: 5px;
  background-color: #222222;
  border-radius: 10px;
}
.f {
  flex: 1 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.y {
  flex: 1 1;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.network__typer2 {
  margin-top: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #1a1c20;
  color: #ddd;
  width: 85%;
  padding: 30px;
  justify-content: space-around;
  margin-bottom: 5px;
}
.network__name {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ddd;
  font-weight: 500;
}
.network {
  font-size: 24px;
  color: #ddd;
  font-weight: 500;
}
.network__name2 {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
}
.network2 {
  font-size: 20px;
  color: #ddd;
  font-weight: 400;
  font-style: normal;
}
.fund__bottom__para {
  text-align: center;
  padding: 0 30px;
}
.fund__bottom__para p {
  font-family: Quicksand, sans-serif;
  font-size: 25px;
  margin-top: 30px;
  line-height: 1.5;
  margin-bottom: 70px;
}

@media screen and (max-width: 1082px) {
  .funds__main__title {
    font-size: 40px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 25px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 20px;
  }
  .network__name {
    font-size: 20px;
  }
  .network {
    font-size: 20px;
  }
  .network__name2 {
    font-size: 17px;
  }
  .network2 {
    font-size: 17px;
  }
  .fund__bottom__para p {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 752px) {
  .funds__main__title {
    font-size: 30px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 16px;
  }
  .network__name {
    font-size: 16px;
  }
  .network {
    font-size: 16px;
  }
  .network__name2 {
    font-size: 14px;
  }
  .network2 {
    font-size: 14px;
  }
  .fund__bottom__para p {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 590px) {
  .funds__main__title {
    font-size: 25px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 15px;
  }
  .network__name {
    font-size: 15px;
  }
  .network {
    font-size: 15px;
  }
  .network__name2 {
    font-size: 13px;
  }
  .network2 {
    font-size: 13px;
  }
  .fund__bottom__para p {
    font-size: 13px;
    text-align: center;
  }
}

@media screen and (max-width: 478px) {
  .funds__main__title {
    font-size: 20px;
  }
  .funds__main__title2 {
    margin-bottom: 10px;
    font-size: 17px;
    color: #ddd;
    word-spacing: 10px;
  }
  
  .funds__top_p {
    font-size: 14px;
    padding: 0 10px;
  }
  .network__name {
    font-size: 14px;
  }
  .network {
    font-size: 14px;
  }
  .network__name2 {
    font-size: 12px;
  }
  .network2 {
    font-size: 12px;
  }
  .fund__bottom__para p {
    font-size: 12px;
    text-align: center;
  }
  .network__typer {
    width: 90%;
  }
  .network__typer2 {
    width: 90%;
  }
}

@media screen and (max-width: 387px) {
  .network__typer {
    width: 95%;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
  .network__typer2 {
    width: 95%;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }
}

