.nodes__container {
  background-color: black;
  /* margin-top: -9px; */
  text-align: center;
  color: #ddd;
}

.nodes__main__title {
  margin-bottom: 16px;
  font-family: "Monoton", cursive;
  font-size: 60px;

  font-weight: 400;
  line-height: 1.3;
  margin-top: 50px;
  letter-spacing: 4px;
  word-spacing: 10px;
}
.hw {
}

.nodes__top__para {
  text-align: center;
  max-width: 600px;
  font-family: "Quicksand", sans-serif;
  color: #fff;
  margin: 50px auto;
  font-size: 25px;
}
.nodes__ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.nodes__li__contains {
  display: flex;
  align-items: left;
  list-style: decimal;
}
.nodes__li__contains p {
  text-align: left;
  font-family: "Quicksand", sans-serif;

  font-size: 25px;
  padding: 0px 150px;
}
.jk {
  margin-bottom: 50px;
}

@media screen and (max-width: 992px) {
  .nodes__main__title {
    font-size: 40px;
  }
  .nodes__top__para {
    font-size: 19px;
  }
  .nodes__li__contains p {
    font-size: 18px;
    padding: 0 70px;
    text-align: left;
  }
}

@media screen and (max-width: 866px) {
  .nodes__main__title {
    font-size: 34px;
    padding: 0 20px;
  }
}

@media screen and (max-width: 654px) {
  .nodes__main__title {
    font-size: 30px;
    padding: 0 10px;
  }
  .nodes__li__contains p {
    font-size: 17px;
  }
  .nodes__top__para {
    font-size: 18px;
  }
}

@media screen and (max-width: 552px) {
  .nodes__main__title {
    font-size: 20px;
    margin-top: -20px;
  }
  .nodes__li__contains p {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 20px;
    text-align: center;
  }
  .nodes__top__para {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 20px;
  }
}
@media screen and (max-width: 458px) {
  .nodes__main__title {
    font-size: 17px;
    padding: 0px 5px;
  }
  .nodes__top__para {
    font-size: 12px;
  }
  .nodes__li__contains p {
    font-size: 12px;
  }
}
