@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
}
.timeline-section {
  background-color: #000;
  min-height: 100vh;
  padding: 100px 10px;
}
.timeline-items {
  max-width: 1000px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.timeline-items::before {
  content: "";
  position: absolute;
  width: 5px;
  height: 100%;
  border: 10px dotted #ffc700;
  left: calc(50% - 16px);
}
.timeline-item {
  margin-bottom: 40px;
  width: 100%;
  position: relative;
}
.timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-item:nth-child(odd) {
  padding-right: calc(50% + 30px);
  text-align: right;
}
.timeline-item:nth-child(even) {
  padding-left: calc(50% + 30px);
}
.timeline-dot {
  /* height: 16px;
	width: 16px;
	background-color: #eaa023;
	position: absolute;
	left: calc(50% - 8px);
	border-radius: 50%;
	top:10px; */
  height: 36px;
  width: 36px;
  background-color: #d3af37;
  position: absolute;
  left: calc(50% - 20px);
  border-radius: 50%;
  box-shadow: inset 0 0 5px #999, 0 0 0 5px #dbdbdb, 0 0 13px #000;
  top: 50%;
}

.timeline-date {
  font-size: 18px;
  color: #eaa023;
  margin: 6px 0 15px;
}
.timeline-content {
  padding: 30px 40px;
  object-fit: cover;
  /* background-image: url("../../assets/leftroad.webp"); */
}
.timeline-item:nth-child(odd) {
}
.df {
  position: relative;
  display: flex;
  gap: 7px;
}
.df:nth-child(2) {
  display: flex;
  justify-content: flex-start;
}

.timeline-content h3 {
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 18px;
  color: #000;
  font-family: "Fredoka One", cursive;
  text-align: left;
}
.timeline-content p {
  font-family: Merienda, cursive;
  text-align: left;
  font-size: 20px;
}

@media screen and (max-width: 990px) {
  .timeline-items {
    padding: 0 30px;
  }
  .timeline-dot {
    width: 30px;
    height: 30px;
  }
  .timeline-items::before {
    width: 3px;
  }
}
/* responsive */
@media (max-width: 767px) {
  .timeline-items::before {
    left: 7px;
  }
  .timeline-item:nth-child(odd) {
    padding-right: 0;
    text-align: left;
  }
  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    padding-left: 37px;
  }
  .timeline-content p {
    font-size: 17px;
  }
  .timeline-dot {
    left: -3.6%;
    top: 49%;
  }
}

@media screen and (max-width: 708px) {
  .timeline-dot {
    width: 20px;
    height: 20px;
  }
  .timeline-items::before {
    border: 7px dotted #ffc700;
  }
  .timeline-item:nth-child(even) {
    padding-left: 20px;
  }
  .timeline-item:nth-child(odd) {
    padding-left: 20px;
  }
  .timeline-content {
    padding: 25px 40px;
  }
}
@media screen and (max-width: 602px) {
  .timeline-items {
    /* padding: 0 10px; */
  }
  .timeline-dot {
    margin-left: -1%;
  }
  .timeline-content p {
    font-size: 14px;
  }
  .timeline-content h3 {
    font-size: 16px;
  }
}
