* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 4px;
}
.scroll-to-top{
  background-color: #ffc700 !important;
  color: aliceblue !important;
 
}
