@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

.dao__container {
  display: flex;
  background-color: black;
  color: white;
  width: 100%;
}
.dao__para__title {
  font-family: "Monoton", cursive;
  font-weight: 400;
  font-size: 47px;
  text-align: center;
  color: #caced3;
}
.daimond__para {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* min-width: 100px; */
}
.diamond__sub__para {
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
  font-size: 23px;
  /* max-width: 700px; */
  width: 90%;
  text-align: center;
  color: #caced3;
  line-height: 1.6;
}
.dao__para {
  flex: 1;
  margin-top: 120px;
  margin-bottom: 70px;
  padding: 0px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dao__videocard {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  margin-left: -13%;
  margin-top: 5%;
  /* margin-left: -20%; */
}
.dao__btns {
  display: inline-block;
  padding: 10px 44px;
  color: #000;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  border: 3px solid #ffc700;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
  margin-top: 30px;
}

.dao__btns:hover {
  background-color: #ffc700;
}
.video video {
  width: 550px;
  height: 100%;
}

@media screen and (max-width: 1351px) {
  .dao__para {
    padding: 0 50px;
  }
  .dao__videocard {
    margin-left: -5%;
  }
}

@media screen and (max-width: 1265px) {
  .video video {
    width: 100%;
  }
}

@media screen and (max-width: 1125px) {
  .dao__container {
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .diamond__sub__para {
    max-width: 1100px;
    width: 100%;
  }
  .dao__videocard {
    margin-left: 0;
    /* margin-top: 0; */
    margin-bottom: 0px;
  }
  .dao__btns {
    margin-bottom: 0;
  }
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .video video {
    width: 90%;
  }
}

@media screen and (max-width: 684px) {
  .dao__para__title {
    font-size: 35px;
  }
  .diamond__sub__para {
    font-size: 17px;
  }
}

@media screen and (max-width: 553px) {
  .dao__para__title {
    font-size: 25px;
    margin-top: -30px;
  }
  .diamond__sub__para {
    font-size: 14px;
  }
  .video video {
    width: 100%;
    padding: 0 10px;
  }
}
@media screen and (max-width: 458px) {
  .dao__para__title {
    font-size: 19px;
  }
  .diamond__sub__para {
    padding: 0;
    width: 100%;
  }
  .dao__para {
    padding: 0 20px;
  }
  .dao__btns {
    font-size: 12px;
    padding: 10px 30px;
  }
}
