.economics__container {
  background-color: #000;
  width: 100%;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.economics__title {
  margin-bottom: 16px;
  font-family: "Monoton", cursive;
  font-size: 60px;
  color: #ddd;
  font-weight: 400;
  word-spacing: 10px;
}
.economics__para {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  margin-top: 50px;
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
  text-align: center;
  line-height: 1.6;
}
.vf {
  background-color: #ddd;
  margin-bottom: 100px;
}
@media screen and (max-width: 1118px) {
  .economics__title {
    font-size: 37px;
  }
  .economics__para {
    font-size: 19px;
  }
}
@media screen and (max-width: 750px) {
  .economics__title {
    font-size: 29px;
  }
  .economics__para {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 560px) {
  .economics__title {
    font-size: 20px;
  }
  .economics__para {
    padding: 0 20px;
    font-size: 17px;
  }
}
@media screen and (max-width: 460px) {
  .economics__title {
    font-size: 19px;
  }
  .economics__para {
    padding: 0 10px;
    font-size: 14px;
  }
}
