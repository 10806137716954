.ct-socials {
  position: fixed;
  top: 20%;
  right: 0;
  list-style: none;
  padding-left: 0;
  z-index: 10;
  margin: 0;
  -webkit-transition: right 0.25s ease-in-out;
  transition: right 0.25s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ct-socials li {
  padding: 0px 0;
}
.ct-socials li:hover {
  background-color: #fff;
}
.ct-socials li a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffc700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  width: 60px;
  height: 54px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  float: right;
}
.ct-socials li a:hover {
      width: 54px;
      padding-left: 0;
      text-decoration: none;
      background: #fff;
}
.section.triad-section {
  margin-top: 10px;
}
section.section h2 {
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 0;
}
h2 {
  color: #000;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  letter-spacing: -1px;
}
section.section p.sub-heading {
  font-size: 16px;
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;
}
section.triad-section .triad-sub-section {
  padding-right: 60px;
}
section p.detail-paragraph:first-child {
  margin-top: 0;
}
section p.detail-paragraph {
  font-family: "Open Sans Condensed", sans-serif;
  margin-top: 40px;
  font-size: 18px;
  color: #000;
}
b,
strong {
  font-weight: 700;
}
