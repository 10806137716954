.main__bot__container {
  background-color: black;
  color: #ddd;
  width: 100%;
  overflow: hidden;
  text-align: center;

  /* height: calc(100% + 200px); */
}
.nc {
  /* margin-top: -30%; */
  margin-bottom: 30px;
}

.bot__para {
  /* margin-top: 10%; */
  padding: 10% 20px;
}

.main-bot {
  width: 100vw;
  background: black;
  display: flex;
  height: 100vh;
  color: aliceblue;
}
@keyframes spin3 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes spin4 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bot-topic {
  margin-bottom: 36px;
  font-family: "Monoton", cursive;
  font-size: 62px;
  color: #ddd;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 50px;
  letter-spacing: 4px;
  /* word-spacing: 10px; */
}
.divided-items {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  min-height: 100vh;
}
.left-item {
  margin-top: 380px;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bot-img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  animation: spin4 5s linear infinite;
  /* animation: spin4 2s infinite linear; */
}
.bot-img1 {
  width: 350px;
  height: 350px;
  border-radius: 50%;

  animation: spin3 5s infinite linear;
}
.center-item {
  flex: 1;
  margin-top: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.bot-para {
  /* max-width: 700px; */
  margin-left: auto;
  margin-right: auto;
  color: #ddd;
  /* margin-bottom: 10px; */
  /* margin-top: 50px; */
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
}
.bot-paras {
  margin-left: auto;
  margin-right: auto;
  color: #ddd;
  /* margin-bottom: 10px; */
  /* margin-top: 50px; */
  font-family: "Quicksand", sans-serif;
  font-size: 25px;
}

.right-item {
  flex: 1;
  margin-top: 380px;

  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1354px) {
  .bot-topic {
    font-size: 40px;
  }
  .left-item {
    margin-top: -10px;
  }
  .right-item {
    margin-top: -10px;
  }
  .center-item {
    margin-top: 100px;
  }
  .nc {
    margin-bottom: 0;
  }
  .main-bot {
    height: 70vh;
  }
  .bot-para {
    padding: 0px;
  }
}

@media screen and (max-width: 1259px) {
  .bot-topic {
    padding: 0 10px;
  }
  .bot-img {
    width: 270px;
    height: 270px;
  }
  .bot-img1 {
    width: 270px;

    height: 270px;
  }
  .bot-para {
    font-size: 20px;
  }
  .bot-paras {
    font-size: 18px;
  }
}

@media screen and (max-width: 1012px) {
  .bot-topic {
    font-size: 30px;
  }
  .bot-img {
    width: 200px;
    height: 200px;
  }
  .bot-img1 {
    width: 200px;
    height: 200px;
  }
  .bot-para {
    font-size: 17px;
  }
  .bot-paras {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (max-width: 684px) {
  .bot-topic {
    font-size: 25px;
  }
  .divided-items {
    flex-direction: column;
  }
  .left-item {
    margin-top: 150px;
  }
  .bot-img1 {
    display: none;
  }
  .center-item {
    margin-top: 0;
  }
  .right-item {
    margin-top: 0;
  }
  .bot-para {
    padding: 0 20px;
  }
  .bot-paras {
    padding: 0 20px;
  }
}

@media screen and (max-width: 553px) {
  .bot-topic {
    font-size: 19px;
    padding: 0 10px;
  }
  .bot-para {
    font-size: 14px;
  }
  .bot-paras {
    font-size: 14px;
  }
}

@media screen and (max-width: 458px) {
  .bot-topic {
    font-size: 15px;
  }
  .bot-para {
    font-size: 12px;
  }
  .bot-paras {
    font-size: 12px;
  }
  .bot-img {
    width: 180px;
    height: 180px;
  }
}
